import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Http } from './http-client-service';
import { HttpClientModule } from '@angular/common/http'
import { ItemDetailModalComponent } from './item-detail-modal/item-detail-modal.component';
@NgModule({
  declarations: [AppComponent,ItemDetailModalComponent],
  entryComponents: [ItemDetailModalComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot({mode:'md'}),
    AppRoutingModule
  ],
  providers: [
  	Http,
  	HttpClientModule,
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
