const HTTP_TIMEOUT: number = 30000;

interface Environment {
    base_url: string;
    client_id:string;
    client_secret:string;
    timeout:number;
}

var prod: Environment = {
    base_url: "https://api.discountstation.com.au/",
    client_id: "2",
    client_secret: "Vp5G0OXYqWIF8R7hFqDli5yImXZf7y7ASBs9dNRq",
    timeout: HTTP_TIMEOUT
}

var staging: Environment = {
    base_url: "http://54.179.130.206/khatriexcel_staging/public/",
    client_id: "2",
    client_secret: "Vp5G0OXYqWIF8R7hFqDli5yImXZf7y7ASBs9dNRq",
    timeout: HTTP_TIMEOUT
}

export const env: Environment = prod
