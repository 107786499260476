import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class Http {
    http:any;
    constructor(@Inject(HttpClient) _http:HttpClient) {
        this.http = _http;
    }

    get(url) {
        return this.http.get(url, {
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage["auth"]).access_token,
            'Accept': 'application/json'
          }
        });
    }

    post(url, data, headerParams?,signup?) {
      // if(!data.token){
      //   data['token']=localStorage.getItem('token');
      // }
      // let headers = headerParams? headerParams:new HttpHeaders();
      // this.createAuthorizationHeader(headers);
      return this.http.post(url, data);
      // ,{
      //   headers: headers
      // }
    }

}
