import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from "@ionic/angular"
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private nav:NavController) {

  }

  canActivate(
  	next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
  	if (localStorage['login'] == 'true') {
  		return true
  	}
  	this.nav.navigateRoot(['/login'])
  }

}
