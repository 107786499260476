import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-item-detail-modal',
  templateUrl: './item-detail-modal.component.html',
  styleUrls: ['./item-detail-modal.component.scss'],
})
export class ItemDetailModalComponent implements OnInit {

  @Input() code

  selected:number = 1

  list:any = []

  constructor(
    private modal:ModalController,
    private loadingController:LoadingController,
    private api:ApiService,
    private alertController:AlertController
  ) {}
  
  select = (index) => {
    if (this.selected == index) this.selected = 0
    else this.selected = index
  }

  ngOnInit() {
    console.log(this.code)
    this.loadingController.create().then(loader => {
      loader.present()
      
      this.api.get_scanned_item({barcode: this.code}).subscribe(data => {
        if (data) {
          this.list = data.data
        }
        loader.dismiss()
      },error => {
        loader.dismiss()
        this.alertController.create({
          header: 'Alert',
          message: 'Something went wrong...',
          buttons: ['Okay']
        }).then((res) => {
          res.present()
        })
      })
    })
  }

  close() {
    this.modal.dismiss()
  }

}
