import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard'

const routes: Routes = [
  {
  	path: '', redirectTo: 'item-list',
    pathMatch: 'full'
  },
  {
  	path: 'login',
  	loadChildren: './login/login.module#LoginPageModule'
  },
  {
  	path: 'item-list',
  	loadChildren: './item-list/item-list.module#ItemListPageModule',
  	canActivate: [AuthGuard]
  },
  {
  	path: 'item-details',
  	loadChildren: './item-details/item-details.module#ItemDetailsPageModule',
  	canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
