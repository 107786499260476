import { Injectable } from '@angular/core';
import { Http } from './http-client-service';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { env } from './env'
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public http: Http) {
  }

  login(data): Observable<any>{
  	console.log(data)
  	return this.http.post(env.base_url+'oauth/token',data).pipe(timeout(env.timeout))
  }

  get_item_list(data): Observable<any>{
  	return this.http.get(env.base_url+'api/items?search='+data.search).pipe(timeout(env.timeout))
  }
  
  get_scanned_item(data): Observable<any>{
  	return this.http.get(env.base_url+'api/items?barcode='+data.barcode).pipe(timeout(env.timeout))
  }

  get_item_details(data): Observable<any>{
  	return this.http.get(env.base_url+'api/items/'+data.id).pipe(timeout(env.timeout))
  }
}
